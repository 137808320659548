import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../services/AuthService";
import { AppContext } from "../../utils/AppProvider";
import { randomSecurityCode } from "../../utils/constant";
import { useFormik } from "formik";
import { object, string, number } from "yup";
import ConfirmationPopupModal from "../ConfirmationPopupModal";

const Login = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [passwordType, setpasswordType] = useState(false);
  const [loginAttempt, setloginAttempt] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [incorrectPassPopup, setincorrectPassPopup] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  // const [state, setState] = useState({
  //   username: "",
  //   password: "",
  //   code: "",
  // });
  const { setIsAuthenticated } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (loginAttempt === 0) {
      window.scrollTo(0, 0);
    }
    setSecurityCode(randomSecurityCode());
  }, [loginAttempt]);

  // const handleInputChange = (e) => {
  //   let { name, value } = e.target;
  //   setState({ ...state, [name]: value });
  // };

  const AdminSchema = object().shape({
    username: string()
      .required("Please enter username.")
      .trim("Please enter username."),

    password: string()
      .required("Please enter password.")
      .trim("Please enter password."),
  });

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: AdminSchema,

      initialValues: {
        username: "",
        password: "",
        code: "",
      },
      onSubmit: async (values, { resetForm }) => {
        submitClicked();
      },

      onReset: () => {},
    });

  const submitClicked = async () => {
    // e.preventDefault();
    if (validateInput()) {
      setisLoading(true);
      await login(values)
        .then((response) => {
          setisLoading(false);
          localStorage.setItem("is_authenticated", 1);
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem(
            "adjustment_balance",
            response?.data?.data?.adjustment_balance || 0.0
          );
          localStorage.setItem(
            "opening_balance",
            response?.data?.data?.opening_balance || 0.0
          );
          localStorage.setItem("isVendor", response?.data?.data?.isVendor);
          localStorage.setItem("isRetailer", response?.data?.data?.isRetailer);
          localStorage.setItem("userId", response?.data?.data?.userId);
          setIsAuthenticated(1);
          setincorrectPassPopup(false);
          seterrorMessage("");
          toast("Customer Login Successfully", { type: "success" });
          navigate("/");
        })
        .catch((error) => {
          setisLoading(false);
          setloginAttempt(loginAttempt + 1);
          setincorrectPassPopup(true);
          seterrorMessage(
            error?.response?.data?.message ||
              error.response.data?.error ||
              "Something went wrong,Please try again"
          );
          //   if (error?.response?.status == 422)
          //     toast(error.response.data.error, { type: "error" });
          //   else if (error?.response?.status == 500)
          //     toast(error.response.data.message, { type: "error" });
          //   else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    // if (state.username == "") {
    //   toast("Please enter username.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (state.password == "") {
    //   toast("Please enter password.", {
    //     type: "error",
    //   });
    //   return false;
    // }

    if (values.code == "") {
      // toast("Please enter code.", {
      //   type: "error",
      // });
      setincorrectPassPopup(true);
      seterrorMessage("Please enter Security code.");
      return false;
    } else if (values.code != securityCode) {
      // toast("Security code does not match.", {
      //   type: "error",
      // });
      setincorrectPassPopup(true);
      seterrorMessage("Security code does not match.");
      return false;
    }

    return true;
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </a>
            <span /> Login
          </div>
        </div>
      </div>
      <div className="page-content login-page pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="row">
                <div className="col-lg-6 pr-30 d-none d-lg-block">
                  {/* <img
                                        className="border-radius-15"
                                        src="assets/imgs/page/login-1.png"
                                        alt=""
                                    /> */}
                  <video
                    width="100%"
                    loading="lazy"
                    muted="muted"
                    src="/images/login-illustartion.mp4"
                    type="video/mp4"
                    autoPlay="autoplay"
                    loop="loop"
                  ></video>
                </div>
                <div className="col-lg-6 col-md-8">
                  <div className="login_wrap widget-taber-content background-white">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h1 className="mb-5">Login</h1>
                        <p className="mb-30 login-hover">
                          Don't have an account?{" "}
                          <Link to="/register">Create here</Link>
                        </p>
                      </div>
                      <form method="post">
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="username"
                            placeholder="Username *"
                            value={values.username}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{
                              borderColor:
                                touched?.username && errors?.username
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                            // onChange={(e) => handleInputChange(e)}
                          />
                          {touched?.username && errors?.username && (
                            <span className="error-text">
                              {errors.username}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            required=""
                            type={passwordType ? "text" : "password"}
                            name="password"
                            placeholder="Your password *"
                            autoComplete="true"
                            // className="error-input"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            style={{
                              borderColor:
                                touched?.password && errors?.password
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                            // onChange={(e) => handleInputChange(e)}
                          />
                          <img
                            onClick={() => setpasswordType(!passwordType)}
                            src={
                              "/images/theme/icons/" +
                              (passwordType
                                ? "icon-eye.svg"
                                : "icon-eye-off.svg")
                            }
                            className="show-password"
                          />
                          {touched?.password && errors?.password && (
                            <span className="error-text">
                              {errors.password}
                            </span>
                          )}
                        </div>
                        <div className="login_footer form-group">
                          <div className="chek-form">
                            <input
                              type="number"
                              required=""
                              min={0}
                              name="code"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Security code *"
                              value={values.code}
                              className="input-border"
                              // onChange={(e) => handleInputChange(e)}
                            />
                          </div>
                          <span className="security-code">
                            {securityCode.split("").map((num, index) => {
                              return index == 0 ? (
                                <b key={index} className="text-new">
                                  {num}
                                </b>
                              ) : index == 1 ? (
                                <b key={index} className="text-hot">
                                  {num}
                                </b>
                              ) : index == 2 ? (
                                <b key={index} className="text-sale">
                                  {num}
                                </b>
                              ) : (
                                <b key={index} className="text-best">
                                  {num}
                                </b>
                              );
                            })}
                          </span>
                        </div>
                        <div className="login_footer form-group mb-50">
                          {/* <div className="chek-form">
                                                        <div className="custome-checkbox">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="checkbox"
                                                                id="exampleCheckbox1"
                                                                defaultValue=""
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleCheckbox1"
                                                            >
                                                                <span>Remember me</span>
                                                            </label>
                                                        </div>
                                                    </div> */}
                          <Link
                            className="text-muted"
                            to="/my-account/lost-password"
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-heading btn-block hover-up"
                            name="login"
                            disabled={isLoading}
                            onClick={(e) => {
                              e?.preventDefault();
                              handleSubmit();
                            }}
                          >
                            {!isLoading ? "Log in" : "Loading..."}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <ConfirmationPopupModal
                  title={"ERROR"}
                  content={`${errorMessage}`}
                  show={incorrectPassPopup}
                  isOk={true}
                  setShow={setincorrectPassPopup}
                  confirmClicked={(e) => {
                    setincorrectPassPopup(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
