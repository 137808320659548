import { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../services/AuthService";
import { AppContext } from "../../utils/AppProvider";
import { isVendor } from "../../utils/constant";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleLogout = async (e) => {
    e.preventDefault();
    await logout()
      .then(() => {
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("opening_balance");
        localStorage.removeItem("adjustment_balance");
        setIsAuthenticated(0);
        localStorage.clear();

        navigate("/");
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div className="col-md-3">
      <div className="dashboard-menu">
        <ul className="nav flex-column" role="tablist">
          <li className="nav-item">
            <Link
              className={
                "nav-link" +
                (location.pathname == "/my-account/" ||
                location.pathname == "/my-account"
                  ? " active"
                  : "")
              }
              id="dashboard-tab"
              to="/my-account"
              role="tab"
              aria-controls="dashboard"
              aria-selected="false"
            >
              <i className="fi-rs-settings-sliders mr-10"></i>Dashboard
            </Link>
          </li>
          {!isVendor() ? (
            <li className="nav-item">
              <Link
                className={
                  "nav-link" +
                  (location.pathname == "/my-account/orders" ? " active" : "")
                }
                id="orders-tab"
                to="/my-account/orders"
                aria-controls="orders"
                aria-selected="false"
              >
                <i className="fi-rs-shopping-bag mr-10"></i>Orders
              </Link>
            </li>
          ) : null}

          <li className="nav-item">
            <Link
              className={
                "nav-link" +
                (location.pathname === "/my-account/addresses" ||
                location.pathname.startsWith(
                  "/my-account/edit-address" ||
                    location.pathname.startsWith("/my-account/add-address")
                )
                  ? " active"
                  : "")
              }
              id="address-tab"
              to="/my-account/addresses"
              role="tab"
              aria-controls="address"
              aria-selected="true"
            >
              <i className="fi-rs-marker mr-10"></i>My Address
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                "nav-link" +
                (location.pathname == "/my-account/account-details"
                  ? " active"
                  : "")
              }
              id="account-detail-tab"
              to="/my-account/account-details"
              role="tab"
              aria-controls="account-detail"
              aria-selected="true"
            >
              <i className="fi-rs-user mr-10"></i>Account details
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" onClick={(e) => handleLogout(e)}>
              <i className="fi-rs-sign-out mr-10"></i>Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
