import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../utils/AppProvider";
import {
  convertToSlug,
  decodeHTMLEntities,
  isRetailer,
  isVendor,
} from "../../utils/constant";
import { addItemToWishlist } from "../../services/ItemService";
import { userId } from "../../layout/Index";
import Blink from "react-blink-text";

const PromotedItems = (props) => {
  const [items, setItems] = useState([]);
  const { wishlist_qty, setWishlistQty } = useContext(AppContext);

  useEffect(() => {
    if (props.items?.length > 0) {
      setItems(props.items);
    }
  }, [props]);

  const wishlistToggle = async (item, e, isGroupToggle) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", item);
    formdata.append("is_group", isGroupToggle);

    await addItemToWishlist(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        let data = [];
        for (let a of items) {
          let value = a;
          if (a?.id === item) {
            if (a?.wishlist) {
              setWishlistQty(wishlist_qty - 1);
            } else {
              setWishlistQty(wishlist_qty + 1);
            }
            value = { ...a, wishlist: a?.wishlist ? 0 : 1 };
          }
          data?.push(value);
        }
        setItems([]);

        setItems(data);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  // const toggleWishlist = async (item, e) => {
  //   e.preventDefault();
  //   let items = await localStorage.getItem("wishlist_items");
  //   let set = 0;
  //   let date = new Date();
  //   date =
  //     date.toLocaleString("default", { month: "long" }) +
  //     " " +
  //     date.getDay() +
  //     ", " +
  //     date.getFullYear();

  //   if (items != null) {
  //     items = JSON.parse(items);
  //     let ids = items.map((item) => item["id"]);

  //     if (ids.includes(item.id)) {
  //       let wishlist_items = items.filter((_item) => _item.id != item.id);
  //       let wishlist_item_ids = wishlist_items.map((_item) => {
  //         return _item.id;
  //       });
  //       set = -1;
  //       setwishlistItems(wishlist_item_ids);
  //       localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
  //       toast("Item Removed from Wishlist", { type: "success" });
  //     } else {
  //       item["wishlist_added_date"] = date;
  //       items.push(item);
  //       set = 1;
  //       setwishlistItems([...wishlistItems, item.id]);
  //       localStorage.setItem("wishlist_items", JSON.stringify(items));
  //       toast("Item added into Wishlist", { type: "success" });
  //     }
  //   } else {
  //     let temp = [];
  //     item["wishlist_added_date"] = date;
  //     temp.push(item);
  //     set = 1;
  //     setwishlistItems([item.id]);
  //     localStorage.setItem("wishlist_items", JSON.stringify(temp));

  //     toast("Item added into Wishlist", { type: "success" });
  //   }
  //   setWishlistQty(wishlist_qty + set);
  // };

  return items.length > 0 ? (
    <section className="section-padding pb-5">
      <div className="container">
        <div className="section-title">
          <h3 className="">Promoted Items</h3>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab-one"
                role="tabpanel"
                aria-labelledby="tab-one"
              >
                <div className="row product-grid-4 m-xl-5 m-lg-3 m-sm-0">
                  {items.map((item, index) => {
                    let ele = document?.getElementById(`no_rightClick${index}`);
                    ele?.addEventListener("contextmenu", (ev) => {
                      ev?.preventDefault(); // this will prevent browser default behavior
                    });
                    return (
                      <div
                        key={index}
                        className="col-lg-1-4 col-md-3 col-12 col-sm-5 inner-product-grid"
                      >
                        <div
                          className="product-cart-wrap mb-50"
                          // id="hover-area"
                          style={{
                            borderColor: "var(--primary-color)",
                            minHeight: "400px",
                          }}
                        >
                          <div className="product-img-action-wrap">
                            <div
                              className="product-img product-img-zoom"
                              id={`no_rightClick${index}`}
                            >
                              <Link
                                to={
                                  "/product/" +
                                  item.id +
                                  "/" +
                                  convertToSlug(item.item_name) +
                                  "/" +
                                  true
                                }
                                state={{ isItem: true }}
                              >
                                <img
                                  className="default-img"
                                  style={{
                                    minHeight: "250px",
                                    maxHeight: "250px",
                                  }}
                                  src={
                                    item?.image
                                      ? process.env.REACT_APP_BASE_URL +
                                        "/storage/products/" +
                                        item.image
                                      : process.env.REACT_APP_BASE_URL +
                                        "/storage/products/no-image.png"
                                  }
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <a
                                aria-label="Add To Favourites"
                                className="action-btn small hover-up"
                                href=""
                                onClick={(e) => {
                                  // toggleWishlist(item, e);
                                  wishlistToggle(item?.id, e, false);
                                }}
                              >
                                {item?.wishlist ? (
                                  <i className="wisilist-fill">&#10084;</i>
                                ) : (
                                  <i className="fi-rs-heart" />
                                )}
                              </a>
                            </div>
                            <>
                              {item?.is_hot ? (
                                <div className="product-badges product-badges-position product-badges-mrg">
                                  <span
                                    className="hot"
                                    style={{
                                      backgroundColor: !item?.is_hot && "gray",
                                    }}
                                  >
                                    Hot
                                  </span>
                                </div>
                              ) : null}
                              {item?.is_clearance ? (
                                <div
                                  className="product-badges product-badges-position product-badges-mrg"
                                  style={{ right: "0", left: "auto" }}
                                >
                                  <span
                                    className="hot"
                                    style={{
                                      borderRadius: "0px 15px 0px 20px",
                                    }}
                                  >
                                    Clearance
                                  </span>
                                </div>
                              ) : null}
                            </>
                          </div>
                          <div
                            className="product-content-wrap"
                            style={{ height: "80px" }}
                          >
                            <div className="product-category">
                              <Link
                                to={
                                  "/product-category/" +
                                  item.category?.slug +
                                  "/1"
                                }
                              >
                                {decodeHTMLEntities(
                                  item.category?.category_name
                                )}
                              </Link>
                            </div>
                            <h2>
                              <Link
                                to={
                                  "/product/" +
                                  item.id +
                                  "/" +
                                  convertToSlug(item.item_name) +
                                  "/" +
                                  true
                                }
                                state={{ isItem: true }}
                                className="ellipsis"
                                style={{
                                  padding: "0px",
                                  // minHeight: "60px",
                                  // minWidth: "265px",
                                }}
                              >
                                {decodeHTMLEntities(item.item_name)}
                              </Link>
                            </h2>
                            {items[index]?.stock <= 0 && (
                              <div
                                style={{
                                  textAlign: "end",
                                  marginRight: "30px",
                                }}
                              >
                                <Blink
                                  color="red"
                                  text="Out Of Stock!"
                                  fontSize="50"
                                >
                                  Out of stock!
                                </Blink>
                              </div>
                            )}
                          </div>

                          <div
                            className="product-content-wrap"
                            // style={{ paddingTop: !item?.isItem && "218px" }}
                            style={{ paddingTop: "0px" }}
                          >
                            {!isVendor() ? (
                              <>
                                <div className="product-card-bottom">
                                  <div className="row">
                                    <span className="product-price">
                                     
                                        <span>
                                          ${item?.sales_price?.toFixed(2)}
                                        </span>
                                      
                                      {!isRetailer() ? (
                                        <span className="old-price">
                                          ${item.discount_price?.toFixed(2) || 0}
                                        </span>
                                      ) : null}
                                    </span>
                                    <span className="user-id-font">
                                      <span> {userId}</span>
                                    </span>
                                  </div>
                                  <div className="detail-qty group-item-qty border radius">
                                    <a
                                      onClick={(e) => {
                                        if (items[index]?.stock > 0) {
                                          props.handleCartQuantityPromotedProduct(
                                            index,
                                            Number(item.cart?.quantity - 1)
                                          );
                                        }
                                      }}
                                      className="qty-down"
                                    >
                                      <i className="fi-rs-angle-small-down" />
                                    </a>
                                    <input
                                      type="text"
                                      name="cart_qty"
                                      className="qty-val"
                                      min={0}
                                      disabled={items[index]?.stock <= 0}
                                      onChange={(e) => {
                                        if (items[index]?.stock > 0) {
                                          props.handleCartQuantityPromotedProduct(
                                            index,
                                            e.target.value
                                          );
                                        }
                                      }}
                                      value={item.cart?.quantity}
                                    />
                                    <a
                                      onClick={(e) => {
                                        if (items[index]?.stock > 0) {
                                          props.handleCartQuantityPromotedProduct(
                                            index,
                                            Number(item.cart?.quantity + 1)
                                          );
                                        }
                                      }}
                                      className="qty-up"
                                    >
                                      <i className="fi-rs-angle-small-up" />
                                    </a>
                                  </div>
                                  {/* <input type='number' min={0} name="cart_qty" className="form-control cart_input" value={item.cart?.quantity} onChange={(e) => props.handleCartQuantityPromotedProduct(index,e.target.value)}/> */}
                                </div>

                                <a
                                  href=""
                                  onClick={(e) => {
                                    e?.preventDefault();
                                    if (items[index]?.stock > 0) {
                                      props.handleAddToCart(item, e);
                                    }
                                  }}
                                  className="btn w-100 hover-up mt-10"
                                  style={{
                                    padding: "8px",
                                    backgroundColor:
                                      items[index]?.stock <= 0 && "gray",
                                  }}
                                >
                                  <i className="fi-rs-shopping-cart mr-5" />
                                  Add To Cart
                                </a>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span className="product-price">
                                  <span style={{ color: "black" }}>
                                    {"Stock:-"}
                                  </span>
                                  <span>{items[index]?.stock || 0}</span>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/*End tab-pane*/}
            </div>
            {/*End tab-content*/}
          </div>
          {/*End Col-lg-9*/}
        </div>
      </div>
    </section>
  ) : null;
};

export default PromotedItems;
