import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addItemToCart,
  addItemToWishlist,
  retriveRecentViewedItems,
} from "../services/ItemService";
import {
  convertToSlug,
  decodeHTMLEntities,
  isLogin,
  isRetailer,
  isVendor,
} from "../utils/constant";
import { AppContext } from "../utils/AppProvider";
import { useContext } from "react";
import { getCartItemQty } from "../services/CartService";
import { userId } from "../layout/Index";
import Blink from "react-blink-text";

const RecentViewProducts = (props) => {
  const [items, setItems] = useState([]);
  const { setCartQty, wishlist_qty, setWishlistQty } = useContext(AppContext);

  useEffect(() => {}, []);

  useEffect(() => {
    getItems();
  }, [props.prod_id]);

  const getItems = () => {
    let viewd_items = localStorage.getItem("recent-view-product");

    if (viewd_items != null && props.prod_id != undefined) {
      viewd_items = JSON.parse(viewd_items);
      let ids = viewd_items.join(",");

      retriveRecentViewedItems(ids, props.prod_id)
        .then((response) => {
          if (response.data.data) {
            let data = response.data.data;
            data.map((item) => {
              if (item.cart == null) {
                let obj = {
                  item_id: item.id,
                  quantity: 0,
                };
                item.cart = obj;
              }
            });
            setItems(data);
          }
        })
        .catch((error) => {
          console.log("Error", error.response);
        });
    }
  };

  // const toggleWishlist = async (item, e) => {
  //   e.preventDefault();
  //   let items = await localStorage.getItem("wishlist_items");
  //   let set = 0;
  //   let date = new Date();
  //   date =
  //     date.toLocaleString("default", { month: "long" }) +
  //     " " +
  //     date.getDay() +
  //     ", " +
  //     date.getFullYear();

  //   if (items != null) {
  //     items = JSON.parse(items);
  //     let ids = items.map((item) => item["id"]);

  //     if (ids.includes(item.id)) {
  //       let wishlist_items = items.filter((_item) => _item.id != item.id);
  //       let wishlist_item_ids = wishlist_items.map((_item) => {
  //         return _item.id;
  //       });
  //       set = -1;
  //       setwishlistItems(wishlist_item_ids);
  //       localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
  //       toast("Item Removed from Wishlist", { type: "success" });
  //     } else {
  //       item["wishlist_added_date"] = date;
  //       items.push(item);
  //       set = 1;
  //       setwishlistItems([...wishlistItems, item.id]);
  //       localStorage.setItem("wishlist_items", JSON.stringify(items));
  //       toast("Item added into Wishlist", { type: "success" });
  //     }
  //   } else {
  //     let temp = [];
  //     set = 1;
  //     item["wishlist_added_date"] = date;
  //     temp.push(item);
  //     setwishlistItems([item.id]);
  //     localStorage.setItem("wishlist_items", JSON.stringify(temp));

  //     toast("Item added into Wishlist", { type: "success" });
  //   }
  //   setWishlistQty(wishlist_qty + set);
  // };

  const wishlistToggle = async (item, e, isGroupToggle) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", item);
    formdata.append("is_group", isGroupToggle);

    await addItemToWishlist(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        let data = [];
        for (let a of items) {
          let value = a;
          if (a?.id === item) {
            if (a?.wishlist) {
              setWishlistQty(wishlist_qty - 1);
            } else {
              setWishlistQty(wishlist_qty + 1);
            }
            value = { ...a, wishlist: a?.wishlist ? 0 : 1 };
          }
          data?.push(value);
        }
        setItems(data);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const handleAddToCart = (item, e) => {
    e.preventDefault();
    if (isLogin()) {
      let item_id = item.id;
      let quantity = item.cart.quantity;
      if (quantity <= 0) {
        // getItems();
        toast("Please Enter the quantity", { type: "error" });
        return;
      }
      addItemToCart(item_id, parseInt(quantity))
        .then((response) => {
          if (response.data.message == "no_stock") {
            toast("Item Stock Not Availbale.", { type: "error" });
          } else {
            CountCartQty();
            toast("Item Added To Cart Successfully.", { type: "success" });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const handleCartQuantity = (index, value) => {
    let flag = false;
    if (!isNaN(value) && value >= 0) {
      if (items[index].stock >= value) {
        let _product = items;
        let _item = items[index].cart;
        _item["quantity"] = value;
        _product[index]["cart"] = _item;
        setItems([..._product]);
        flag = true;
      } else if (items[index].stock <= 0) {
        toast("Out Of Stock", { type: "error" });
      } else {
        toast(
          `Please Enter proper quantity,there are only ${items[index]?.stock} stock left.`,
          { type: "error" }
        );
      }
    } else {
      // getItems();
      flag = true;
      toast("Please Enter the quantity", { type: "error" });
    }
    if (!flag) {
      let _product = items;
      let _item = items[index].cart;
      _item["quantity"] = 0;
      _product[index]["cart"] = _item;
      setItems([..._product]);
    }
  };

  const CountCartQty = () => {
    getCartItemQty()
      .then((response) => {
        setCartQty(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
        if (error?.response?.status == 422)
          toast(error.response.data.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      {items.length > 0 ? (
        <div className="row mt-60">
          <div className="col-12">
            <h2 className="section-title style-1 mb-30">
              Recently Viewed Products
            </h2>
          </div>
          <div className="col-12">
            <div className="row related-products product-grid-4 m-xl-5 m-lg-3 m-sm-0">
              {items.map((item, index) => {
                let dis = document?.getElementById(
                  `disable-rightclick-${index}`
                );
                dis?.addEventListener("contextmenu", (ev) => {
                  ev?.preventDefault(); // this will prevent browser default behavior
                });
                return (
                  <div
                    key={index}
                    className="col-lg-1-4 col-md-3 col-12 col-sm-5 inner-product-grid"
                  >
                    <div
                      className="product-cart-wrap hover-up mb-20"
                      // id="hover-area"
                      style={{
                        borderColor: "var(--primary-color)",
                        minHeight: "400px",
                      }}
                    >
                      <div className="product-img-action-wrap">
                        <div
                          className="product-img product-img-zoom"
                          id={`disable-rightclick-${index}`}
                        >
                          <Link
                            to={
                              "/product/" +
                              item.id +
                              "/" +
                              convertToSlug(item.item_name) +
                              "/" +
                              true
                            }
                            state={{ isItem: true }}
                          >
                            <img
                              className="default-img"
                              style={{ minHeight: "250px", maxHeight: "250px" }}
                              src={
                                item?.image
                                  ? process.env.REACT_APP_BASE_URL +
                                    "/storage/products/" +
                                    item.image
                                  : process.env.REACT_APP_BASE_URL +
                                    "/storage/products/no-image.png"
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="product-action-1">
                          <a
                            aria-label="Add To Favourites"
                            className="action-btn small hover-up"
                            href=""
                            tabIndex={0}
                            onClick={(e) => {
                              // toggleWishlist(item, e);
                              wishlistToggle(item?.id, e, false);
                            }}
                          >
                            {item?.wishlist ? (
                              <i className="wisilist-fill">&#10084;</i>
                            ) : (
                              <i className="fi-rs-heart" />
                            )}
                          </a>
                        </div>
                        <>
                          {item?.is_hot ? (
                            <div className="product-badges product-badges-position product-badges-mrg">
                              <span
                                className="hot"
                                style={{
                                  backgroundColor: !item?.is_hot && "gray",
                                }}
                              >
                                Hot
                              </span>
                            </div>
                          ) : null}
                          {item?.is_clearance ? (
                            <div
                              className="product-badges product-badges-position product-badges-mrg"
                              style={{ right: "0", left: "auto" }}
                            >
                              <span
                                className="hot"
                                style={{
                                  borderRadius: "0px 15px 0px 20px",
                                }}
                              >
                                Clearance
                              </span>
                            </div>
                          ) : null}
                        </>
                      </div>

                      <div
                        className="product-content-wrap"
                        style={{ height: "80px" }}
                      >
                        <div className="product-category">
                          <Link
                            to={
                              "/product-category/" +
                              item.category?.slug +
                              "/1" +
                              "/"
                              // + true
                            }
                            state={{ isItem: true }}
                            // style={{ padding: "5px", minHeight: "55px" }}
                          >
                            {decodeHTMLEntities(item.category?.category_name)}{" "}
                          </Link>
                        </div>
                        <h2>
                          <Link
                            to={
                              "/product/" +
                              item.id +
                              "/" +
                              convertToSlug(item.item_name) +
                              "/" +
                              true
                            }
                            style={{
                              padding: "0px",
                              // minHeight: "60px",
                            }}
                            className="ellipsis"
                            state={{ isItem: true }}
                          >
                            {decodeHTMLEntities(item.item_name)}
                          </Link>
                        </h2>
                        {items[index]?.stock <= 0 && (
                          <div
                            style={{
                              textAlign: "end",
                              marginRight: "30px",
                            }}
                          >
                            <Blink
                              color="red"
                              text="Out Of Stock!"
                              fontSize="50"
                            >
                              Out of stock!
                            </Blink>
                          </div>
                        )}
                      </div>

                      <div
                        className="product-content-wrap"
                        style={{ paddingTop: "0px" }}
                      >
                        {!isVendor() ? (
                          <>
                            <div className="product-card-bottom">
                              <div className="row">
                                <span className="product-price">
                                  <span>${item?.sales_price?.toFixed(2)}</span>

                                  {!isRetailer() ? (
                                    <span className="old-price">
                                      ${item.discount_price?.toFixed(2) || 0}
                                    </span>
                                  ) : null}
                                </span>
                                <span className="user-id-font">
                                  <span>{userId}</span>
                                </span>
                              </div>
                              <div className="detail-qty group-item-qty border radius">
                                <a
                                  onClick={(e) => {
                                    if (items[index]?.stock > 0) {
                                      handleCartQuantity(
                                        index,
                                        Number(item.cart?.quantity) - 1
                                      );
                                    }
                                  }}
                                  className="qty-down"
                                >
                                  <i className="fi-rs-angle-small-down" />
                                </a>
                                <input
                                  type="text"
                                  name="cart_qty"
                                  className="qty-val"
                                  min={0}
                                  disabled={items[index]?.stock <= 0}
                                  onChange={(e) => {
                                    if (items[index]?.stock > 0) {
                                      handleCartQuantity(index, e.target.value);
                                    }
                                  }}
                                  value={item.cart?.quantity}
                                />
                                <a
                                  onClick={(e) => {
                                    if (items[index]?.stock > 0) {
                                      handleCartQuantity(
                                        index,
                                        Number(item.cart?.quantity) + 1
                                      );
                                    }
                                  }}
                                  className="qty-up"
                                >
                                  <i className="fi-rs-angle-small-up" />
                                </a>
                              </div>
                              {/* <input type='number' min={0} name="cart_qty" className="form-control cart_input" value={item.cart?.quantity} onChange={(e) => handleCartQuantity(index,e.target.value)}/> */}
                            </div>
                            <a
                              href=""
                              onClick={(e) => {
                                e?.preventDefault();
                                if (items[index]?.stock > 0) {
                                  handleAddToCart(item, e);
                                }
                              }}
                              className="btn w-100 hover-up mt-10"
                              style={{
                                padding: "8px",
                                backgroundColor:
                                  items[index]?.stock <= 0 && "gray",
                              }}
                            >
                              <i className="fi-rs-shopping-cart mr-5" />
                              Add To Cart
                            </a>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span className="product-price">
                              <span style={{ color: "black" }}>
                                {"Stock:-"}
                              </span>
                              <span>{items[index]?.stock || 0}</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecentViewProducts;
