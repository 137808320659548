import { createRef, useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RecentViewProducts from "../../common/RecentViewProducts";
import {
  addItemToCart,
  retriveItem,
  retriveGroupDetails,
  addSelectedItemToCart,
  addItemToWishlist,
} from "../../services/ItemService";
import { Link } from "react-router-dom";
import RelatedProducts from "./RelatedProducts";
import { toast } from "react-toastify";
import {
  convertToSlug,
  decodeHTMLEntities,
  isLogin,
  isRetailer,
  isVendor,
} from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";
import { getCartItemQty } from "../../services/CartService";
import _ from "lodash";
import Blink from "react-blink-text";
import { userId } from "../../layout/Index";
import ImagePopUp from "./ImagePopUp";
import Loader from "../Loader";

function ImageMagnifier({
  src,
  width,
  ref,
  height,
  magnifierHeight = 250,
  magnifieWidth = 250,
  zoomLevel = 1.5,
  disableZoom,
}) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  return (
    <div
      style={{
        position: "relative",
        height: height,
        width: width,
      }}
      id="no_right_click"
    >
      <img
        src={src}
        ref={ref}
        style={{ height: height, width: width }}
        onMouseEnter={(e) => {
          if (!disableZoom) {
            // update image size and turn-on magnifier
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
          }
        }}
        onMouseMove={(e) => {
          if (!disableZoom) {
            // update cursor position
            const elem = e.currentTarget;
            const { top, left } = elem.getBoundingClientRect();

            // calculate cursor position on the image
            const x = e.pageX - left - window.pageXOffset;
            const y = e.pageY - top - window.pageYOffset;
            setXY([x, y]);
          }
        }}
        onMouseLeave={() => {
          // close magnifier

          setShowMagnifier(false);
        }}
        alt={"img"}
      />

      <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",

          // prevent maginier blocks the mousemove event of img
          pointerEvents: "none",
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: "1", // reduce opacity so you can verify position
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url('${src}')`,
          backgroundRepeat: "no-repeat",

          //calculate zoomed image size
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,

          //calculete position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></div>
    </div>
  );
}

const Product = () => {
  const [item, setItem] = useState({});
  const params = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [Image, setImage] = useState();
  const [page, setPage] = useState(params?.page || 1);
  const [perPage, setPerPage] = useState(10);
  const [links, setLinks] = useState([]);
  const [isImagePopUp, setisImagePopUp] = useState(undefined);
  const navigate = useNavigate();
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!popupRef?.current?.contains(event.target)) {
        setisImagePopUp(undefined);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [popupRef]);

  let ele = document?.getElementById("no_right_click");
  ele?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });

  const isItem =
    params?.isItem === "true" || params?.isItem === true ? true : false;

  const { setCartQty, cart_qty, wishlist_qty, setWishlistQty } =
    useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setRecentViewProduct();
  }, [params?.id, params?.isItem]);

  useEffect(() => {
    // if (params?.page !== page) {
    // navigate(
    //   `/product/${params?.id}/${params?.title}/${params?.isItem}/${page}`
    // );
    getItem();
    window.scrollTo(0, 0);
    // }
  }, [page, params?.id, params?.isItem]);

  const noPhotoFound =
    process.env.REACT_APP_BASE_URL + "/storage/products/no-image-product.png";

  const getItem = async () => {
    setisLoading(true);
    let item_id = params.id;
    if (isItem) {
      await retriveItem(item_id)
        .then((response) => {
          if (response.data.data) {
            setisLoading(false);

            let data = "";
            if (response.data.data.group_items.length > 0) {
              let group_items = response.data.data.group_items;
              group_items.map((item) => {
                if (item.cart == null) {
                  let obj = {
                    item_id: item.id,
                    quantity: 0,
                  };
                  item.cart = obj;
                }
              });
              // group_items.map((i)=>{i.qty = 0})
              setItem(response.data.data);
            } else {
              // response.data.data.qty = 1;
              data = response.data.data;
              if (data.cart == null) {
                let obj = {
                  item_id: data.id,
                  quantity: 0,
                };
                data.cart = obj;
              }
              setItem(data);
            }
            if (isItem) {
              response?.data?.data?.images[0] &&
                setImage(
                  process.env.REACT_APP_BASE_URL +
                    "/storage/products/" +
                    response?.data?.data?.images[0]
                );
            } else {
              if (response?.data?.data?.groupDetail?.groups_image) {
                setImage(
                  process.env.REACT_APP_BASE_URL +
                    "/storage/group_images/" +
                    response?.data?.data?.groupDetail?.groups_image
                );
              }
            }
            // setImage(response?.data.data?.groupDetail?.groups_image ? process.env.REACT_APP_BASE_URL +isItem? "/storage/products/":"/storage/group_images/" + response?.data.data?.groupDetail?.groups_image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image-product.png")
          }
        })
        .catch((error) => {
          setisLoading(false);

          console.log("Error", error.response);
        });
    } else {
      await retriveGroupDetails(item_id, page, perPage)
        .then((response) => {
          if (response?.data?.data) {
            setisLoading(false);

            let data = "";
            setLinks(response?.data?.data?.links);

            if (response?.data?.data?.data?.length > 0) {
              let group_items = response.data.data.data;
              group_items.map((item) => {
                if (item.cart == null) {
                  let obj = {
                    item_id: item.id,
                    quantity: 0,
                  };
                  item.cart = obj;
                }
              });
              // group_items.map((i)=>{i.qty = 0})
              setItem(response.data.data);
            } else {
              // response.data.data.qty = 1;
              data = response.data.data;
              if (data.cart == null) {
                let obj = {
                  item_id: data.id,
                  quantity: 0,
                };
                data.cart = obj;
              }

              setItem(data);
            }
            if (isItem) {
              response?.data?.data?.images[0] &&
                setImage(
                  process.env.REACT_APP_BASE_URL +
                    "/storage/products/" +
                    response?.data?.data?.images[0]
                );
            } else {
              if (response?.data?.data?.groupDetail?.groups_image) {
                setImage(
                  process.env.REACT_APP_BASE_URL +
                    "/storage/group_images/" +
                    response?.data?.data?.groupDetail?.groups_image
                );
              } else if (response?.data?.data?.groupDetail?.item_image) {
                setImage(
                  process.env.REACT_APP_BASE_URL +
                    "/storage/products/" +
                    response?.data?.data?.groupDetail?.item_image
                );
              }
            }
            // setImage(response?.data.data?.groupDetail?.groups_image ? process.env.REACT_APP_BASE_URL + isItem? "/storage/products/":"/storage/group_images/"  + response?.data.data?.groupDetail?.groups_image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image-product.png")
          }
        })
        .catch((error) => {
          setisLoading(false);

          console.log("Error", error.response);
        });
    }
  };

  const wishlistToggle = async (id, e, isGroupToggle) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", id);
    formdata.append("is_group", isGroupToggle);

    await addItemToWishlist(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });

        if (isItem) {
          if (item?.wishlist) {
            setWishlistQty(wishlist_qty - 1);
          } else {
            setWishlistQty(wishlist_qty + 1);
          }
          setItem({ ...item, wishlist: item?.wishlist ? 0 : 1 });
        } else {
          if (item?.groupDetail?.wishlist) {
            setWishlistQty(wishlist_qty - 1);
          } else {
            setWishlistQty(wishlist_qty + 1);
          }
          setItem({
            ...item,
            groupDetail: {
              ...item?.groupDetail,
              wishlist: item?.groupDetail?.wishlist ? 0 : 1,
            },
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const wishlistToggleForGroupItems = async (id, e, isGroupToggle) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", id);
    formdata.append("is_group", isGroupToggle);

    await addItemToWishlist(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });

        let array = [];
        for (let a of item?.data) {
          if (a?.id === id) {
            if (a?.wishlist) {
              setWishlistQty(wishlist_qty - 1);
              array?.push({ ...a, wishlist: a?.wishlist ? 0 : 1 });
            } else {
              setWishlistQty(wishlist_qty + 1);
              array?.push({
                ...a,
                wishlist: a?.wishlist ? 0 : 1,
              });
            }
          } else {
            array?.push(a);
          }
        }
        setItem({ ...item, data: array });
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const setRecentViewProduct = () => {
    let items = localStorage.getItem("recent-view-product");

    if (items != null) {
      items = JSON.parse(items);

      if (!items.includes(params.id)) {
        items.push(params.id);
        localStorage.setItem("recent-view-product", JSON.stringify(items));
      }
    } else {
      items = [params.id];
      localStorage.setItem("recent-view-product", JSON.stringify(items));
    }
  };

  const handleAddToCart = (flag = false) => {
    if (isLogin()) {
      if (flag == true) {
        let flag = false;
        item.group_items.map((i) => {
          if (i.cart.quantity != 0) {
            flag = true;
          }
        });
        if (flag) {
          addSelectedItemToCart(item.group_items, 0)
            .then((response) => {
              CountCartQty();
              getItem();
              toast("Item Added To Cart Successfully.", { type: "success" });
            })
            .catch((error) => {
              if (error?.response?.status == 422)
                toast(error.response.data.message, { type: "error" });
              else if (error?.response?.status == 500)
                toast(error.response.data.message, { type: "error" });
              else toast("Something went wrong", { type: "error" });
            });
        } else {
          toast("Select Item Quantity.", { type: "error" });
        }
      } else {
        let item_id = item.id;
        let quantity = item.cart.quantity;
        if (quantity <= 0) {
          toast("Please Enter the quantity", { type: "error" });
          return;
        }
        addItemToCart(item_id, quantity)
          .then((response) => {
            if (response.data.message == "no_stock") {
              toast("Item Stock Not Availbale.", { type: "error" });
            } else {
              CountCartQty();
              toast("Item Added To Cart Successfully.", { type: "success" });
            }
          })
          .catch((error) => {
            console.log("Error", error);
            if (error?.response?.status == 422)
              toast(error.response.data.message, { type: "error" });
            else if (error?.response?.status == 500)
              toast(error.response.data.message, { type: "error" });
            else toast("Something went wrong", { type: "error" });
          });
      }
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const handleAddToCartForGroup = (flag = false) => {
    if (flag == true) {
      // let flag = false;
      // item.data.map((i) => {
      //   if (i.cart.quantity != 0) {
      //     flag = true;
      //   }
      // });
      if (flag) {
        addSelectedItemToCart(item.data, 0)
          .then((response) => {
            CountCartQty();
            getItem();
            toast("Item Added To Cart Successfully.", { type: "success" });
          })
          .catch((error) => {
            if (error?.response?.status == 422)
              toast(error.response.data.message, { type: "error" });
            else if (error?.response?.status == 500)
              toast(error.response.data.message, { type: "error" });
            else toast("Something went wrong", { type: "error" });
          });
      } else {
        toast("Select Item Quantity.", { type: "error" });
      }
    } else {
      let item_id = item.id;
      let quantity = item.cart.quantity;
      if (quantity <= 0) {
        toast("Please Enter the quantity", { type: "error" });
        return;
      }
      addItemToCart(item_id, quantity)
        .then((response) => {
          if (response.data.message == "no_stock") {
            toast("Item Stock Not Availbale.", { type: "error" });
          } else {
            CountCartQty();
            toast("Item Added To Cart Successfully.", { type: "success" });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const handleimage = (url) => {
    setImage(url);
  };

  const incrementQty = (qty, item1, index) => {
    if (isItem) {
      qty += 1;
      if (Number(qty) < Number(item1.stock)) {
        let _items = item.group_items;
        let obj = item.group_items[index].cart;
        obj["quantity"] = _.add(Number(obj["quantity"]), 1);
        _items[index]["cart"] = obj;
        setItem({ ...item, ["group_items"]: _items });
      } else {
        toast("Out Of Stock", { type: "error" });
      }
    } else {
      qty += 1;
      if (Number(qty) <= Number(item1.stock)) {
        let _items = item.data;
        let obj = item.data[index].cart;
        obj["quantity"] = _.add(Number(obj["quantity"]), 1);
        _items[index]["cart"] = obj;

        setItem({ ...item, data: _items });
      } else {
        toast("Out Of Stock", { type: "error" });
      }
    }
  };

  const decrementQty = (qty, item1, index) => {
    if (isItem) {
      qty -= 1;
      if (qty >= 0) {
        let _items = item.group_items;
        let obj = item.group_items[index].cart;
        obj["quantity"] = obj.quantity - 1;
        _items[index]["cart"] = obj;
        setItem({ ...item, ["group_items"]: _items });
      }
    } else {
      qty -= 1;
      if (qty >= 0) {
        let _items = item.data;
        let obj = item.data[index].cart;
        obj["quantity"] = obj.quantity - 1;
        _items[index]["cart"] = obj;
        setItem({ ...item, ["data"]: _items });
      }
    }
  };

  const handleCartQuantity = (index, value) => {
    if (isItem) {
      let flag = false;
      if (!isNaN(value) && value >= 0) {
        if (item.group_items[index].stock >= value) {
          let _items = item.group_items;
          let obj = item.group_items[index].cart;
          obj["quantity"] = value;
          _items[index]["cart"] = obj;
          setItem({ ..._items, ["group_items"]: _items });
          flag = true;
        } else if (item.group_items[index].stock <= 0) {
          toast("Out Of Stock", { type: "error" });
        } else {
          toast("Please Enter the quantity", { type: "error" });
        }
      } else {
        flag = true;
        toast("Please Enter the quantity", { type: "error" });
      }
      if (!flag) {
        let _items = item.group_items;
        let obj = item.group_items[index].cart;
        obj["quantity"] = 0;
        _items[index]["cart"] = obj;
        setItem({ ..._items, ["group_items"]: _items });
      }
    } else {
      let flag = false;
      if (!isNaN(value) && value >= 0) {
        if (item.data[index].stock >= value) {
          let _items = item.data;
          let obj = item.data[index].cart;
          obj["quantity"] = value;
          _items[index]["cart"] = obj;
          setItem({ ..._items, ["data"]: _items });
          flag = true;
        } else if (item.data[index].stock <= 0) {
          toast("Out Of Stock", { type: "error" });
        } else {
          toast("Please Enter the quantity.", { type: "error" });
        }
      } else {
        flag = true;
        toast("Please Enter the quantity", { type: "error" });
      }
      if (!flag) {
        let _items = item.data;
        let obj = item.data[index].cart;
        obj["quantity"] = 0;
        _items[index]["cart"] = obj;
        setItem({ ..._items, ["data"]: _items });
      }
    }
  };

  const CountCartQty = () => {
    getCartItemQty()
      .then((response) => {
        setCartQty(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
        if (error?.response?.status == 422)
          toast(error.response.data.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span />{" "}
            <Link
              to={
                isItem
                  ? "/product-category/" +
                    convertToSlug(item?.category?.slug) +
                    "/1"
                  : "/product-category/" +
                    convertToSlug(item?.groupDetail?.category?.slug) +
                    "/1"
              }
            >
              {" "}
              {isItem
                ? decodeHTMLEntities(item.category?.category_name)
                : decodeHTMLEntities(item?.groupDetail?.title)}
            </Link>{" "}
            <span />
            {isItem && <>{decodeHTMLEntities(item.item_name)}</>}
          </div>
        </div>
      </div>
      <div className="container mb-30">
        <div className="row">
          {!isLoading ? (
            <div className="col-xl-10 col-lg-12 m-auto">
              <div className="product-detail accordion-detail">
                <div className="row mb-50 mt-30">
                  <div
                    className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <div className="detail-gallery">
                      {/* <span className="zoom-icon">
                                            <i className="fi-rs-search" />
                                        </span> */}
                      {/* MAIN SLIDES */}
                      <div
                        className="product-image-slider "
                        // slick-initialized slick-slider"
                      >
                        <div
                        // className="slick-list draggable"
                        // style={{
                        //   minHeight: "450px",
                        //   minWidth: "450px",
                        //   maxWidth: "450px",
                        //   maxWidth: "450px",
                        // }}
                        >
                          <div
                            id="no_right_click"

                            //  className="slick-track"
                          >
                            <figure
                            // className="border-radius-10 slick-slide slick-current slick-active "
                            // className={`zoom-container ${
                            //   isZoomed ? "zoomed" : ""
                            // }`}
                            // onMouseMove={handleMouseMove}
                            // onMouseEnter={() => setIsZoomed(true)}
                            // onMouseLeave={() => setIsZoomed(false)}
                            // data-slick-index={1}
                            // onMouseMove={onScroll}
                            // aria-hidden="false"
                            // tabIndex={1}
                            >
                              <ImageMagnifier
                                width={"400px"}
                                height={"400px"}
                                src={_.isEmpty(Image) ? noPhotoFound : Image}
                                disableZoom={!Image && true}
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      {/* THUMBNAILS */}

                      {item.images?.length > 1 ? (
                        <div className="slider-nav-thumbnails slick-initialized slick-slider">
                          {/*  <button
                                                    type="button"
                                                    className="slick-prev slick-arrow"
                                                    style={{}}
                                                >
                                                    <i className="fi-rs-arrow-small-left" />
                                                </button> */}
                          <div className="slick-list draggable">
                            <div
                              className="slick-track"
                              style={{
                                opacity: 1,
                                // width: 3006,
                                // transform: "translate3d(-668px, 0px, 0px)"
                              }}
                            >
                              {item.images.map((image, index) => {
                                return (
                                  <div
                                    className="slick-slide slick-cloned"
                                    data-slick-index={1}
                                    key={index}
                                    aria-hidden="true"
                                    tabIndex={1}
                                    style={{
                                      height: 150,
                                      width: 150,
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      handleimage(
                                        process.env.REACT_APP_BASE_URL +
                                          "/storage/products/" +
                                          image
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        Image
                                          ? process.env.REACT_APP_BASE_URL +
                                            isItem
                                            ? "/storage/products/"
                                            : "/storage/group_images/" + image
                                          : noPhotoFound
                                      }
                                      height={140}
                                      width={140}
                                      style={{ marginBottom: "10px" }}
                                      alt="product_image"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {/*  <button
                                                    type="button"
                                                    className="slick-next slick-arrow"
                                                    style={{}}
                                                >
                                                    <i className="fi-rs-arrow-small-right" />
                                                </button> */}
                        </div>
                      ) : null}
                    </div>
                    {/* End Gallery */}
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 mobile-single-product">
                    <div className="detail-info pr-30 pl-30">
                      {/* {isItem&&<span className="stock-status out-stock"> Sale Off </span>} */}
                      <h2 className="title-detail">
                        {isItem
                          ? decodeHTMLEntities(item.item_name)
                          : decodeHTMLEntities(item?.groupDetail?.title)}
                      </h2>
                      {isItem && item?.is_clearance ? (
                        <div
                          className="mt-2"
                          style={{ color: "#e57d26", fontWeight: "bold" }}
                        >
                          CLEARANCE
                        </div>
                      ) : null}
                      {isItem && (
                        <div className="product-detail-rating">
                          <div className="product-rate-cover text-end">
                            <div className="product-rate d-inline-block">
                              <div
                                className="product-rating"
                                style={{ width: "90%" }}
                              />
                            </div>
                            <span className="font-small ml-5 text-muted">
                              {" "}
                              (32 reviews)
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="clearfix product-price-cover">
                        <div className="product-price primary-color float-left">
                          {isItem && (
                            <div className="row">
                              {!isVendor() ? (
                                <>
                                  <span className="current-price text-brand">
                                    ${item?.sales_price?.toFixed(2)}
                                    {!isRetailer() ? (
                                      <span className="old-price font-md ml-15">
                                        ${item?.discount_price?.toFixed(2)}
                                      </span>
                                    ) : null}
                                  </span>
                                  <span className="">
                                    <span
                                      className="user-id-font"
                                      style={{ marginLeft: "15px" }}
                                    >
                                      {" "}
                                      {userId}
                                    </span>
                                  </span>
                                </>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="current-price text-brand">
                                    <span style={{ color: "black" }}>
                                      {"Stock:-"}
                                    </span>
                                    <span>{item?.stock || 0}</span>
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                          {isItem && (
                            <span>
                              <span className="save-price font-md color3 ml-15">
                                {/* {((item.sales_price - item.sales_price) * 100 / item.sales_price).toFixed(0)}% Off */}
                              </span>
                              {/* <span className="old-price font-md ml-15">${item.sales_price}</span> */}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="short-desc mb-30">
                        <p className="font-lg">
                          {/* {decodeHTMLEntities(item?.description)} */}
                        </p>
                      </div>
                      <div className="attr-detail attr-size mb-30 d-none">
                        <strong className="mr-10">Size / Weight: </strong>
                        <ul className="list-filter size-filter font-small">
                          <li>
                            <a href="#">50g</a>
                          </li>
                          <li className="active">
                            <a href="#">60g</a>
                          </li>
                          <li>
                            <a href="#">80g</a>
                          </li>
                          <li>
                            <a href="#">100g</a>
                          </li>
                          <li>
                            <a href="#">150g</a>
                          </li>
                        </ul>
                      </div>
                      {!isItem && (
                        <div>
                          Category :{" "}
                          <span className="text-brand">
                            {decodeHTMLEntities(
                              item?.groupDetail?.category?.category_name
                            )}
                          </span>
                        </div>
                      )}
                      {item?.stock <= 0 && (
                        <div
                          style={
                            {
                              // textAlign: "end",
                              // marginRight: "35px",
                            }
                          }
                        >
                          <Blink color="red" text="Out Of Stock!" fontSize="50">
                            Out of stock!
                          </Blink>
                        </div>
                      )}

                      <div className="detail-extralink mb-50">
                        {item?.group_items?.length <= 0 && !isVendor() ? (
                          <div className="detail-qty border radius">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (item.cart.quantity >= 1) {
                                  setItem({
                                    ...item,
                                    cart: {
                                      ...item.cart,
                                      quantity: item.cart.quantity - 1,
                                    },
                                  });
                                } else {
                                  // toast("Out Of Stock", { type: "error" });
                                }
                              }}
                              className="qty-down"
                            >
                              <i className="fi-rs-angle-small-down" />
                            </a>
                            <input
                              type="text"
                              name="quantity"
                              className="qty-val"
                              min={1}
                              disabled={item?.stock <= 0}
                              onChange={(e) => {
                                e.preventDefault();
                                if (e.target.value <= item.stock) {
                                  setItem({
                                    ...item,
                                    cart: {
                                      ...item.cart,
                                      quantity: e.target.value,
                                    },
                                  });
                                } else {
                                  // toast("Out Of Stock", { type: "error" });
                                }
                              }}
                              value={item.cart.quantity}
                            />
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (item.cart.quantity < item.stock) {
                                  setItem({
                                    ...item,
                                    cart: {
                                      ...item.cart,
                                      quantity: _.add(
                                        Number(item.cart.quantity),
                                        1
                                      ),
                                    },
                                  });
                                } else {
                                  toast("Out Of Stock", { type: "error" });
                                }
                              }}
                              className="qty-up"
                            >
                              <i className="fi-rs-angle-small-up" />
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="product-extra-link2">
                          {item?.group_items?.length <= 0 &&
                            isItem &&
                            !isVendor() && (
                              <>
                                {isItem && (
                                  <button
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      if (item?.stock > 0) {
                                        handleAddToCart();
                                      }
                                    }}
                                    style={{
                                      backgroundColor:
                                        item?.stock <= 0 && "gray",
                                    }}
                                    // type="submit"
                                    className="button button-add-to-cart"
                                  >
                                    <i className="fi-rs-shopping-cart" />
                                    Add to cart
                                  </button>
                                )}
                              </>
                            )}

                          <a
                            aria-label="Add To Favourites"
                            className={`action-btn hover-up ${
                              !isItem && "mt-5"
                            }`}
                            href=""
                            onClick={(e) => {
                              // toggleWishlist(item, e);
                              wishlistToggle(
                                isItem ? item?.id : item?.groupDetail?.id,
                                e,
                                !isItem
                              );
                            }}
                          >
                            {isItem ? (
                              <>
                                {item?.wishlist ? (
                                  <i className="wisilist-fill">&#10084;</i>
                                ) : (
                                  <i className="fi-rs-heart" />
                                )}
                              </>
                            ) : (
                              <>
                                {item?.groupDetail?.wishlist ? (
                                  <i className="wisilist-fill">&#10084;</i>
                                ) : (
                                  <i className="fi-rs-heart" />
                                )}
                              </>
                            )}
                          </a>
                          {isItem && item?.is_hot ? (
                            <a
                              aria-label="Add To Favourites"
                              className={`action-btn hover-up ${
                                !isItem && "mt-5"
                              }`}
                              // href=""
                            >
                              <i className="wisilist-fill">🔥</i>
                            </a>
                          ) : null}
                        </div>
                      </div>

                      <div className="font-xs col-xs-12">
                        <ul className="mr-50 float-start">
                          {isItem && item?.brand?.brand_name && (
                            <li className="mb-5">
                              Brand :{" "}
                              <span className="text-brand">
                                {" "}
                                {item?.brand?.brand_name}
                              </span>
                            </li>
                          )}

                          {isItem && item?.product_code && (
                            <li className="mb-5">
                              Product Code :{" "}
                              <span className="text-brand">
                                {" "}
                                {item?.product_code}
                              </span>
                            </li>
                          )}
                          <li className="mb-5">
                            {isItem && (
                              <>
                                Category :{" "}
                                <span className="text-brand">
                                  {decodeHTMLEntities(
                                    item?.category?.category_name
                                  )}
                                </span>
                              </>
                            )}
                          </li>
                          {isItem && item?.color?.title && (
                            <li className="mb-5">
                              Color :{" "}
                              <span className="text-brand">
                                {" "}
                                {item?.color?.title}
                              </span>
                            </li>
                          )}
                          {isItem && item?.size?.title && (
                            <li className="mb-5">
                              Size :{" "}
                              <span className="text-brand">
                                {" "}
                                {decodeHTMLEntities(item?.size?.title)}
                              </span>
                            </li>
                          )}
                          {/* {isItem&& <li className="mb-5">
                                                MFG {" "}: <span className="text-brand">  {" "}
                                                        {(() => {
                                                            date = new Date(item.created_date);
                                                            if (date != null && date != 'Invalid Date') {
                                                                return date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate() + ', ' + date.getFullYear();
                                                            }
                                                        })()


                                                        }
                                                    </span>
                                                </li>} */}
                        </ul>
                        <ul className="float-start">
                          {isItem && (
                            <li className="mb-5">
                              {/* SKU{" "}:  <span className="text-brand">{" "}{item.sku}</span> */}
                            </li>
                          )}
                          <li className="mb-5 d-none">
                            Tags :
                            {isItem && (
                              <a href="#" rel="tag">
                                {" "}
                                Snack
                              </a>
                            )}
                            ,{" "}
                            {isItem && (
                              <a href="#" rel="tag">
                                Organic
                              </a>
                            )}
                            ,{" "}
                            {isItem && (
                              <a href="#" rel="tag">
                                Brown
                              </a>
                            )}
                          </li>
                          {/* {isItem && (
                          <li className="mb-5">
                            Stock :
                            <span className="in-stock text-brand ml-5">
                              {" "}
                              {item.stock} Items In Stock
                            </span>
                          </li>
                        )} */}
                          {isItem && (
                            <li className="mb-5">
                              {/* Barcode{" "}:<span className="text-brand">{" "}{item?.custom_barcode}</span> */}
                            </li>
                          )}
                          {isItem && item?.flavor?.title && (
                            <li className="mb-5">
                              Flavor :{" "}
                              <span className="text-brand">
                                {" "}
                                {item?.flavor?.title}
                              </span>
                            </li>
                          )}
                          {isItem && item?.unit?.unit_name && (
                            <li className="mb-5">
                              Unit :{" "}
                              <span className="text-brand">
                                {" "}
                                {item?.unit?.unit_name}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {/* Detail Info */}
                    {item?.group_items?.length > 0 && isItem && (
                      <div className="table-responsive group_item_table col-md-12 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                        <table className="table no-border">
                          <thead>
                            <tr>
                              {!isVendor() && <th>Qty</th>}
                              <th>Flavor</th>
                              <th>Color</th>
                              <th>Size</th>
                              {!isVendor() && <th>Price</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {item?.group_items?.map((item, index) => {
                              return (
                                <tr key={index} className="group-item">
                                  {!isVendor() && (
                                    <td>
                                      <div
                                        className="detail-qty group-item-qty border radius"
                                        style={{
                                          maxWidth: "61px",
                                          marginRight: "25px",
                                          width: "162%",
                                        }}
                                      >
                                        <a
                                          onClick={(e) =>
                                            decrementQty(
                                              item.cart.quantity,
                                              item,
                                              index
                                            )
                                          }
                                          className="qty-down"
                                        >
                                          <i className="fi-rs-angle-small-down" />
                                        </a>
                                        <input
                                          style={{ paddingLeft: "15px" }}
                                          type="text"
                                          name="quantity"
                                          className="qty-val"
                                          min={0}
                                          onChange={(e) =>
                                            handleCartQuantity(
                                              index,
                                              e.target.value
                                            )
                                          }
                                          value={item.cart.quantity}
                                        />
                                        <a
                                          onClick={(e) => {
                                            e?.preventDefault();
                                            incrementQty(
                                              item.cart.quantity,
                                              item,
                                              index
                                            );
                                          }}
                                          className="qty-up"
                                        >
                                          <i className="fi-rs-angle-small-up" />
                                        </a>
                                      </div>
                                    </td>
                                  )}
                                  <td>{item?.flavor?.title ?? "NA"}</td>
                                  <td>{item?.color?.title ?? "NA"}</td>
                                  <td>
                                    {item?.size?.title
                                      ? decodeHTMLEntities(item?.size?.title)
                                      : "NA"}
                                  </td>
                                  {!isVendor() && (
                                    <td>
                                      <span className="current-price text-brand">
                                        ${item?.sales_price?.toFixed(2)}
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {item?.group_items?.length > 0 && !isVendor() ? (
                          <button
                            onClick={() => handleAddToCart(true)}
                            type="submit"
                            className="button button-add-to-cart"
                          >
                            <i className="fi-rs-shopping-cart" />
                            Add to cart
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {!isItem && item?.data?.length && (
                      <div className="table-responsive group_item_table col-md-12 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                        <table className="table no-border">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Image</th>
                              <th>Product Code</th>
                              {isVendor() && <th>Stock</th>}
                              {/* <th>Favourite</th> */}
                              {/* <th>Product Name</th> */}
                              {!isVendor() && <th>Qty</th>}
                              <th>Unit </th>
                              <th>Unit Count</th>
                              <th>Status</th>
                              {!isVendor() && <th>Price</th>}
                              <th>Flavor</th>
                              <th>Color</th>
                              <th>Size</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.data?.map((item, index) => {
                              const noPhotoFound =
                                process.env.REACT_APP_BASE_URL +
                                "/storage/products/no-image-product.png";
                              const thumbnailImage =
                                process.env.REACT_APP_BASE_URL +
                                "/storage/products/" +
                                item?.item_image;
                              let elee = document?.getElementById(
                                `no_rightclick${index}`
                              );
                              elee?.addEventListener("contextmenu", (ev) => {
                                ev?.preventDefault(); // this will prevent browser default behavior
                              });
                              let isOutOfStock = item?.stock === 0;
                              return (
                                <tr key={index} className="group-item">
                                  <td>
                                    <a
                                      aria-label="Add To Favourites"
                                      className={`action-btn hover-up 
                                      
                                    `}
                                      href=""
                                      onClick={(e) => {
                                        // toggleWishlist(item, e);
                                        wishlistToggleForGroupItems(
                                          item?.id,
                                          e,
                                          false
                                        );
                                      }}
                                    >
                                      {item?.wishlist ? (
                                        <i className="wisilist-fill">
                                          &#10084;
                                        </i>
                                      ) : (
                                        <i className="fi-rs-heart" />
                                      )}
                                    </a>
                                  </td>
                                  <td>
                                    <div id={`no_rightclick${index} d-flex`}>
                                      <img
                                        className="default-img"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          height: "50px",
                                          width: "50px",
                                        }}
                                        src={
                                          item?.item_image
                                            ? thumbnailImage
                                            : noPhotoFound
                                        }
                                        alt=""
                                        onClick={() => {
                                          setisImagePopUp(index);
                                        }}
                                      />

                                      {isImagePopUp === index && (
                                        <ImagePopUp
                                          popupRef={popupRef}
                                          src={
                                            item?.item_image
                                              ? thumbnailImage
                                              : noPhotoFound
                                          }
                                          closeClicked={() =>
                                            setisImagePopUp(undefined)
                                          }
                                        />
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      navigate(
                                        `/product/${item?.id}/${convertToSlug(
                                          item?.item_name
                                        )}/true
                                      `
                                      )
                                    }
                                  >
                                    {item?.product_code ?? "NA"}
                                  </td>
                                  {isVendor() && (
                                    <td>
                                      <span className="current-price text-brand">
                                        {item?.stock}
                                      </span>
                                    </td>
                                  )}
                                  {!isVendor() && (
                                    <td>
                                      <div
                                        className="detail-qty group-item-qty border radius"
                                        style={{
                                          maxWidth: "61px",
                                          marginRight: "25px",
                                          width: "162%",
                                        }}
                                      >
                                        <a
                                          onClick={(e) =>
                                            decrementQty(
                                              item?.cart?.quantity,
                                              item,
                                              index
                                            )
                                          }
                                          className="qty-down"
                                        >
                                          <i className="fi-rs-angle-small-down" />
                                        </a>
                                        <input
                                          type="text"
                                          name="quantity"
                                          className="qty-val"
                                          min={0}
                                          onChange={(e) =>
                                            handleCartQuantity(
                                              index,
                                              e.target.value
                                            )
                                          }
                                          value={item?.cart?.quantity}
                                        />
                                        <a
                                          onClick={(e) => {
                                            e?.preventDefault();
                                            incrementQty(
                                              item?.cart?.quantity,
                                              item,
                                              index
                                            );
                                          }}
                                          className="qty-up"
                                        >
                                          <i className="fi-rs-angle-small-up" />
                                        </a>
                                      </div>
                                    </td>
                                  )}
                                  <td>
                                    {decodeHTMLEntities(
                                      item?.unit?.unit_name
                                    ) ?? "NA"}
                                  </td>
                                  <td>{item?.unit_value ?? "NA"}</td>
                                  <td
                                    className="text-center detail-info"
                                    data-title="Stock"
                                  >
                                    {item.stock > 0 ? (
                                      <span
                                        className="stock-status in-stock mb-0"
                                        style={{
                                          padding: "1px",
                                          paddingTop: "5px",
                                          paddingBottom: "5px",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {" "}
                                        In Stock{" "}
                                      </span>
                                    ) : (
                                      <span
                                        className="stock-status out-stock mb-0"
                                        style={{
                                          padding: "1px",
                                          paddingTop: "5px",
                                          paddingBottom: "5px",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {" "}
                                        Stock Out{" "}
                                      </span>
                                    )}
                                  </td>
                                  {!isVendor() && (
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span className="current-price text-brand">
                                        ${item?.sales_price?.toFixed(2)}
                                      </span>
                                      {!isRetailer() ? (
                                        <span
                                          style={{
                                            color: "#5f6a6a",
                                            textDecoration: "line-through",
                                            fontSize: "13px",
                                          }}
                                        >
                                          $
                                          {item.discount_price?.toFixed(2) || 0}
                                        </span>
                                      ) : null}
                                    </td>
                                  )}
                                  <td>{item?.flavor?.title ?? "NA"}</td>

                                  <td>{item?.color?.title ?? "NA"}</td>
                                  <td>
                                    {item?.size?.title
                                      ? decodeHTMLEntities(item?.size?.title)
                                      : "NA"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {!isItem && item?.data?.length > 0 && (
                          <div className="pagination-area mt-20 mb-20">
                            <nav
                              aria-label="Page navigation example"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ul className="pagination justify-content-start">
                                {links?.map((link, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={
                                        "page-item" +
                                        (link.active ? " page-link-active" : "")
                                      }
                                    >
                                      <a
                                        className={
                                          "page-link page-arrow page-link-active"
                                        }
                                        style={{
                                          backgroundColor: link.active
                                            ? "#E57D26"
                                            : "",
                                        }}
                                        href=""
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (link.url) {
                                            setPage(
                                              link.url?.split("page=")[1]
                                            );
                                          }
                                        }}
                                      >
                                        {link.label
                                          .replace(/&laquo; Previous/g, "<<")
                                          .replace(/Next &raquo;/g, ">>")}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </nav>
                          </div>
                        )}
                        {item?.data?.length > 0 && !isVendor() ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={() => handleAddToCartForGroup(true)}
                              type="submit"
                              className="button button-add-to-cart"
                            >
                              <i className="fi-rs-shopping-cart" />
                              Add to cart
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                  {item?.description && (
                    <>
                      <p className=" font-lg">Description : </p>
                      <span className="text-brand">
                        {decodeHTMLEntities(item?.description)}
                      </span>
                    </>
                  )}
                  {item?.groupDetail?.description && (
                    <>
                      <p className=" font-lg">Description : </p>
                      <span className="text-brand">
                        {decodeHTMLEntities(item?.groupDetail?.description)}
                      </span>
                    </>
                  )}
                </div>
                {isItem && (
                  <>
                    {item && (
                      <RelatedProducts
                        category_id={item?.category?.id}
                        item_id={item.id}
                        params={params?.id}
                      />
                    )}
                    {item && <RecentViewProducts prod_id={item.id} />}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                minHeight: "450px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Product;
