import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../services/AuthService";
import { getCartItemQty } from "../../services/CartService";
import { countNotification } from "../../services/NotificationService";
import { AppContext } from "../../utils/AppProvider";
import Notification from "../Notification/Notification";
import ConfirmationPopupModal from "../ConfirmationPopupModal";
import { wishlistCount } from "../../services/ItemService";
import Blink from "react-blink-text";

import _ from "lodash";
import { isLogin, isVendor } from "../../utils/constant";

const HeaderMiddle = ({ signOutClicked }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [signOutPopup, setsignOutPopup] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [adjustment_balance, setadjustment_balance] = useState(0);
  const [opening_balance, setopening_balance] = useState(0);
  const [top, setTop] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const {
    setSearch,
    setCartQty,
    cart_qty,
    setWishlistQty,
    // is_authenticated,
    search,
    wishlist_qty,
    setIsAuthenticated,
    setShowMobileSearch,
  } = useContext(AppContext);

  let is_authenticated = localStorage.getItem("is_authenticated");
  useEffect(async () => {
    is_authenticated = await localStorage.getItem("is_authenticated");

    if (isLogin()) {
      getCartItemQty()
        .then((response) => {
          if (response?.data?.data) {
            setCartQty(response.data.data);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
      await countNotification()
        .then((response) => {
          setNotificationCount(response?.data?.data?.count);
          setopening_balance(response?.data?.data?.opening_balance || 0.0);
          setadjustment_balance(
            response?.data?.data?.adjustment_balance || 0.0
          );
          localStorage.setItem(
            "adjustment_balance",
            response?.data?.data?.adjustment_balance || 0.0
          );
          localStorage.setItem(
            "opening_balance",
            response?.data?.data?.opening_balance || 0.0
          );
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    } else {
      setCartQty(0);
      setSearch("");
    }
  }, [isLogin(), location.pathname]);

  useEffect(async () => {
    await wishlistCount()
      .then((response) => {
        setWishlistQty(response?.data?.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout()
      .then(() => {
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("opening_balance");
        localStorage.removeItem("adjustment_balance");
        setIsAuthenticated(0);
        localStorage.clear();

        setsignOutPopup(false);
        navigate("/login");
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setShowMobileSearch(false);
    navigate(`/products/1`);
  };

  const openNotificationModal = () => {
    setNotificationCount(0);
    setShowNotifications(true);
  };

  const handleNotificationCount = async (id, index) => {
    if (notificationCount > 0) {
      setNotificationCount(notificationCount - 1);
    }
  };

  return (
    <>
      <div
        className={`header-middle header-middle-ptb-1 d-none d-lg-block 
      
        `}
        //   sticky-header ${!top && "shadow-sm"}
      >
        <div className="container">
          <div className="header-wrap">
            <div className="logo logo-width-1">
              <Link to="/">
                <img
                  src="/images/sl_logo.png"
                  alt="logo"
                  style={{ width: "80px", minWidth: "100%" }}
                />
              </Link>
            </div>
            <div className="header-right">
              <div
                className="search-style-2 "
                style={{ backgroundImage: "none" }}
              >
                <form onSubmit={(e) => handleSearch(e)}>
                  <input
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    value={search}
                    type="text"
                    placeholder="Search for products..."
                    // autoComplete="off"
                  />
                  <button
                    type="submit "
                    style={{ marginTop: "3px" }}
                    onClick={(e) => {
                      handleSearch(e);
                    }}
                  >
                    <i
                      className="fi-rs-search "
                      style={{
                        color: "var(--primary-color)",
                        borderRadius: "none",
                        marginRight: "3px",
                      }}
                    ></i>
                  </button>
                </form>
              </div>
              <div className="header-action-right">
                <div className="header-action-2">
                  {/* <div className="header-action-icon-2">
                                    <a href="#shop-compare.html">
                                        <img
                                            className="svgInject"
                                            alt="Nest"
                                            src="/images/theme/icons/icon-compare.svg"
                                        />
                                        <span className="pro-count blue">3</span>
                                    </a>
                                    <a href="#shop-compare.html">
                                        <span className="lable ml-0">Compare</span>
                                    </a>
                                </div> */}
                  <div
                    className="header-action-icon-2"
                    onClick={() => openNotificationModal()}
                  >
                    <Link>
                      <img
                        className="svgInject"
                        alt="Nest"
                        src="/images/theme/icons/notification.svg"
                      />
                      {is_authenticated && (
                        <span className="pro-count blue">
                          {" "}
                          {notificationCount}
                        </span>
                      )}
                    </Link>
                    <Link
                      style={{
                        marginLeft: notificationCount > 0 && "4px",
                      }}
                    >
                      {notificationCount > 0 ? (
                        // <Blink
                        //   color="black"
                        //   text="Notifications!"
                        //   fontSize="40"
                        // >
                        //   <h3 className="lable bolt ">Notifications!</h3>
                        // </Blink>
                        <h6
                          className="lable bolt "
                          style={{ marginTop: notificationCount > 0 && "10px" }}
                        >
                          Notifications!
                        </h6>
                      ) : (
                        <span className="lable bolt ">Notifications!</span>
                      )}
                    </Link>
                  </div>

                  <div className="header-action-icon-2">
                    <Link to="/favourites">
                      <img
                        className="svgInject"
                        alt="Nest"
                        src="/images/theme/icons/icon-heart.svg"
                      />
                      {is_authenticated && (
                        <span className="pro-count blue"> {wishlist_qty}</span>
                      )}
                    </Link>
                    <Link to="/favourites">
                      <span className="lable">Favourites</span>
                    </Link>
                  </div>
                  {!isVendor() && (
                    <div className="header-action-icon-2">
                      <Link className="/cart" to="/cart">
                        <img
                          alt="Nest"
                          src="/images/theme/icons/icon-cart.svg"
                        />
                        {is_authenticated && (
                          <span className="pro-count blue">{cart_qty}</span>
                        )}
                      </Link>
                      <Link to="/cart">
                        <span className="lable">Cart</span>
                      </Link>
                      {/* <div
                      className="cart-dropdown-wrap cart-dropdown-hm2"
                      style={{ display: "none" }}
                    >
                      <ul>
                        <li>
                          <div className="shopping-cart-img">
                            <a href="#shop-product-right.html">
                              <img
                                alt="Nest"
                                src="/images/shop/thumbnail-3.jpg"
                              />
                            </a>
                          </div>
                          <div className="shopping-cart-title">
                            <h4>
                              <a href="#shop-product-right.html">
                                Daisy Casual Bag
                              </a>
                            </h4>
                            <h4>
                              <span>1 × </span>$800.00
                            </h4>
                          </div>
                          <div className="shopping-cart-delete">
                            <a href="##">
                              <i className="fi-rs-cross-small" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="shopping-cart-img">
                            <a href="#shop-product-right.html">
                              <img
                                alt="Nest"
                                src="/images/shop/thumbnail-2.jpg"
                              />
                            </a>
                          </div>
                          <div className="shopping-cart-title">
                            <h4>
                              <a href="#shop-product-right.html">
                                Corduroy Shirts
                              </a>
                            </h4>
                            <h4>
                              <span>1 × </span>$3200.00
                            </h4>
                          </div>
                          <div className="shopping-cart-delete">
                            <a href="##">
                              <i className="fi-rs-cross-small" />
                            </a>
                          </div>
                        </li>
                      </ul>
                      <div className="shopping-cart-footer">
                        <div className="shopping-cart-total">
                          <h4>
                            Total <span>$4000.00</span>
                          </h4>
                        </div>
                        <div className="shopping-cart-button">
                          <a href="#shop-cart.html" className="outline">
                            View cart
                          </a>
                          <a href="#shop-checkout.html">Checkout</a>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  )}
                  {is_authenticated ? (
                    <div className="header-action-icon-2">
                      <Link to="/my-account">
                        <img
                          className="svgInject"
                          alt="Nest"
                          src="/images/theme/icons/icon-user.svg"
                        />
                      </Link>
                      <Link to="/my-account">
                        <span className="lable ml-0">Account</span>
                      </Link>
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                        <ul>
                          <li>
                            <Link to="" style={{ display: "flex" }}>
                              <i
                                className="fi fi-rs-bank mr-10"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              ></i>
                              <p
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>Opening Balance</span>
                                <h3>${opening_balance || 0.0}</h3>
                              </p>
                            </Link>
                          </li>
                          <li>
                            <Link to="" style={{ display: "flex" }}>
                              <i
                                className="fi fi-rs-bank mr-10"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              ></i>
                              <p
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>Available Balance</span>
                                <h5>${adjustment_balance || 0.0}</h5>
                              </p>
                            </Link>
                          </li>
                          <li>
                            <Link to="/my-account">
                              <i className="fi fi-rs-user mr-10" />
                              My Account
                            </Link>
                          </li>
                          {/*  <li>
                                                <a href="#page-account.html">
                                                    <i className="fi fi-rs-location-alt mr-10" />
                                                    Order Tracking
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#page-account.html">
                                                    <i className="fi fi-rs-label mr-10" />
                                                    My Voucher
                                                </a>
                                            </li> */}
                          <li>
                            <Link to="/favourites">
                              <i className="fi fi-rs-heart mr-10" />
                              My Favourites
                            </Link>
                          </li>
                          {/*   <li>
                                                <a href="#page-account.html">
                                                    <i className="fi fi-rs-settings-sliders mr-10" />
                                                    Setting
                                                </a>
                                            </li> */}
                          <li>
                            <a
                              href=""
                              onClick={(e) => {
                                // (e) => handleLogout(e)
                                e.preventDefault();

                                signOutClicked();
                              }}
                            >
                              <i className="fi fi-rs-sign-out mr-10" />
                              Sign out
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Link to="/login" className="btn btn-sm">
                      Sign In
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNotifications == true ? (
        <Notification
          show={() => setShowNotifications(false)}
          count={handleNotificationCount}
        />
      ) : null}
    </>
  );
};

export default HeaderMiddle;
