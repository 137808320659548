import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { handleUsername, resetPassword } from "../../services/AuthService";
import _ from "lodash";
import { object, ref, string } from "yup";
import { useFormik } from "formik";
import ConfirmationPopupModal from "../ConfirmationPopupModal";

const ResetPassword = () => {
  const [state, setState] = useState({
    email: "",
  });
  const [isValid, setIsValid] = useState("");
  const [username, setusername] = useState("");
  const [confirmPassType, setconfirmPassType] = useState(false);
  const [passwordType, setpasswordType] = useState(false);
  const [incorrectPassPopup, setincorrectPassPopup] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    let getSearchParams = window.location.hash.split("?")[1];
    // const urlParams = new URLSearchParams(window.location.search);
    const email = getSearchParams?.split("&")[0]?.split("=")[1];
    const userName = getSearchParams?.split("&")[1]?.split("=")[1];
    const token = getSearchParams?.split("&")[2]?.split("=")[1];
    let storedToken = localStorage.getItem("pass-reset-token");
    setusername(userName);
      setState({
        ...state,
        ["email"]: email,
        username: userName,
      });
    if (storedToken != null && storedToken === token) {
      setIsValid(true);
      // usernameDetails(email);
    } else {
      setIsValid(true);
      // usernameDetails(email);
      // setIsValid(false);
    }
  }, []);

  // const usernameDetails = async (email) => {
  //   await handleUsername({ email: email })
  //     .then((response) => {
  //       setState({
  //         ...state,
  //         ["email"]: email,
  //         username: response?.data?.message?.username,
  //       });
  //       setusername(response?.data?.message?.username);
  //     })
  //     .catch((error) => {
  //       console.log("Error", error.response);
  //       if (error?.response?.status == 422)
  //         toast(error.response.data.error, { type: "error" });
  //       else if (error?.response?.status == 500)
  //         toast(error.response.data.message, { type: "error" });
  //       else toast("Something went wrong", { type: "error" });
  //     });
  // };

  const handlePasswordChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  console.log(username,"<<<")

  const submitClicked = async (data) => {
    // e.preventDefault();
    if (validateInput()) {
      await resetPassword({ ...state, ...data, isNew: _.isEmpty(username) })
        .then((response) => {
          localStorage.removeItem("pass-reset-token");
          toast("Password Reset Sucessfully.", { type: "success" });
          navigate("/login");
        })
        .catch((error) => {
          console.log("Error", error.response);
          setincorrectPassPopup(true);
          seterrorMessage(
            error?.response?.data?.message ||error.response.data?.error||
              "Something went wrong,Please try again"
          );
          //   if (error?.response?.status == 422)
          //     toast(error.response.data.error, { type: "error" });
          //   else if (error?.response?.status == 500)
          //     toast(error.response.data.message, { type: "error" });
          //   else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const AdminSchema = object().shape({
    password: string()
      .required("Please enter password.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one lowercase letter or one uppercase letter, one digit, one special character, and be at least 8 characters long."
      )
      .trim("Please enter password."),

    password_confirmation: string()
      .required("Please enter confirm password.")
      .trim("Please enter confirm password.")
      .oneOf(
        [ref("password")],
        "Password and confirm password does not match."
      ),
  });

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: AdminSchema,

      initialValues: {
        password: "",
        password_confirmation: "",
      },
      onSubmit: async (values, { resetForm }) => {
        submitClicked(values);
      },

      onReset: () => {},
    });

  const validateInput = () => {
    const usernameRegex = /^[a-zA-Z0-9]*$/;

    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (_.isEmpty(username)) {
      if (state.username == "") {
        toast("Please enter Username.", {
          type: "error",
        });
        return false;
      } else if (!usernameRegex.test(state.username)) {
        // toast(
        //   "Username should not be contain any special character or any space.",
        //   {
        //     type: "error",
        //   }
        // );
          setincorrectPassPopup(true);
          seterrorMessage(
            "Username should not be contain any special character or any space."
          );
        return false;
      } else if (
        state.username?.trim()?.length < 6 ||
        state.username?.trim()?.length > 12
      ) {
        // toast("Username Must be between 6 to 12 characters long.", {
        //   type: "error",
        // });
         setincorrectPassPopup(true);
         seterrorMessage("Username Must be between 6 to 12 characters long.");
        return false;
      }
    }

    // if (state.password == "") {
    //   toast("Please enter password.", {
    //     type: "error",
    //   });
    //   return false;
    // }

    // if (state.password_confirmation == "") {
    //   toast("Please enter confirm password.", {
    //     type: "error",
    //   });
    //   return false;
    // }

    // if (!passwordRegex.test(state.password)) {
    //   toast(
    //     "Password must contain at least one lowercase letter or one uppercase letter, one digit, one special character, and be at least 8 characters long.",
    //     {
    //       type: "error",
    //     }
    //   );
    //   return false;
    // }

    // if (state.password_confirmation !== state.password) {
    //   toast("Password and confirm password do not match.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    return true;
  };

  return (
    <>
      <main className="main pages">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                <i className="fi-rs-home mr-5" />
                Home
              </Link>
              <span /> Reset Password
            </div>
          </div>
        </div>
        <div className="page-content pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-8 col-md-12 m-auto">
                <div className="row">
                  {isValid === true ? (
                    <>
                      <div className="heading_s1">
                        <img
                          className="border-radius-15"
                          src="/images/page/reset_password.svg"
                          alt=""
                        />
                        <h2 className="mb-15 mt-15">Set new password</h2>
                        <p className="mb-30">
                          Please create a new password that you don’t use on any
                          other site.
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-8">
                        <div className="login_wrap widget-taber-content background-white">
                          <div className="padding_eight_all bg-white">
                            <form method="post">
                              <div className="form-group">
                                <input
                                  disabled={!_.isEmpty(username)}
                                  name="username"
                                  placeholder="username *"
                                  value={state.username}
                                  onChange={(e) => handlePasswordChange(e)}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type={!passwordType ? "password" : "text"}
                                  placeholder="Password *"
                                  name="password"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.password}
                                  style={{
                                    borderColor:
                                      touched?.password && errors?.password
                                        ? "red"
                                        : `var(--dark-container)`,
                                  }}
                                />
                                <img
                                  alt=""
                                  onClick={() => setpasswordType(!passwordType)}
                                  src={
                                    "/images/theme/icons/" +
                                    (passwordType
                                      ? "icon-eye.svg"
                                      : "icon-eye-off.svg")
                                  }
                                  className="show-password"
                                />
                                {touched?.password && errors?.password && (
                                  <span className="error-text">
                                    {errors.password}
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <input
                                  type={!confirmPassType ? "password" : "text"}
                                  required=""
                                  name="password_confirmation"
                                  placeholder="Confirm you password *"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.password_confirmation}
                                  style={{
                                    borderColor:
                                      touched?.password_confirmation &&
                                      errors?.password_confirmation
                                        ? "red"
                                        : `var(--dark-container)`,
                                  }}
                                />
                                <img
                                  alt=""
                                  onClick={() =>
                                    setconfirmPassType(!confirmPassType)
                                  }
                                  src={
                                    "/images/theme/icons/" +
                                    (confirmPassType
                                      ? "icon-eye.svg"
                                      : "icon-eye-off.svg")
                                  }
                                  className="show-password"
                                />
                                {touched?.password_confirmation &&
                                  errors?.password_confirmation && (
                                    <span className="error-text">
                                      {errors.password_confirmation}
                                    </span>
                                  )}
                              </div>
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn btn-heading btn-block hover-up"
                                  name="login"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  Reset password
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 pl-50">
                        <h6 className="mb-5">Username must:</h6>
                        <ol className="list-insider">
                          <li>Be between 6 and 12 characters</li>
                          <li>
                            Should not be contain any Special character or any
                            space
                          </li>
                        </ol>
                        <h6 className="mb-5 mt-4">Password must:</h6>
                        <ol className="list-insider">
                          <li>Be between 8 and 64 characters</li>
                          <li>A lowercase or uppercase character</li>
                          <li>A number</li>
                          <li>A special character</li>
                        </ol>
                      </div>
                    </>
                  ) : null}
                  {isValid === false ? (
                    <div>
                      <h4 style={{ textAlign: "center", color: "#cd1111" }}>
                        Can't proceed, Reset password process tempered
                      </h4>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <ConfirmationPopupModal
              title={"ERROR"}
              content={`${errorMessage}`}
              show={incorrectPassPopup}
              isOk={true}
              setShow={setincorrectPassPopup}
              confirmClicked={(e) => {
                setincorrectPassPopup(false);
              }}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default ResetPassword;
